<template>
  <div class="signup">
    <div
      :class="{
        'h-centerContent': registerStatus === -1,
      }"
    >
      <section
        class="formContainer"
        :class="{
          posCenter: registerStatus !== -1,
        }"
      >
        <!-- form -->
        <div v-if="feedback === ''">
          <div class="formTop flex items-center flex-col">
            <img src="/images/logo-dark.png" class="mb-15 w-130" alt="Logo" />
            <h2>Sign Up Now</h2>
          </div>
          <div class="formBody">
            <p class="text-center font-size-15 line-h-20">
              Rapid + Robust Insight Solutions in your hands
            </p>

            <div class="form">
              <!-- email -->
              <div class="input-group position-r mb-20">
                <label>Email*</label>
                <div>
                  <input
                    type="email"
                    v-model="email"
                    required
                    class="fullWidth"
                    @blur="validateEmail"
                  />
                  <p class="formErrorMes" v-show="!email_valid">
                    Please input a valid email address
                  </p>
                </div>
              </div>

              <div class="field-row mb-0 flex justify-between gap-15">
                <!-- fname -->
                <div class="input-group position-r mb-20">
                  <label>First name*</label>
                  <div>
                    <input
                      type="text"
                      v-model="fstName"
                      required
                      class="fullWidth"
                      @blur="validateName('fstName')"
                    />
                    <p class="formErrorMes" v-show="!fstName_valid">
                      First name can not be empty
                    </p>
                  </div>
                </div>
                <!-- lname -->
                <div class="input-group position-r mb-20">
                  <label>Last name*</label>
                  <div>
                    <input
                      type="text"
                      v-model="lastName"
                      required
                      class="fullWidth"
                      @blur="validateName('lastName')"
                    />
                    <p class="formErrorMes" v-show="!lastName_valid">
                      Last name can not be empty
                    </p>
                  </div>
                </div>
              </div>
              <div class="field-row mb-0 flex justify-between gap-15">
                <!-- company name -->
                <div class="input-group position-r mb-20">
                  <label>Company name*</label>
                  <div>
                    <input
                      type="text"
                      v-model="cpName"
                      required
                      class="fullWidth"
                      @blur="validateName('company')"
                    />
                    <p class="formErrorMes" v-show="!cpName_valid">
                      Company name can not be empty
                    </p>
                  </div>
                </div>
                <!-- job -->
                <div class="input-group position-r mb-20">
                  <label>Job title*</label>
                  <div>
                    <input
                      type="text"
                      v-model="jobTitle"
                      required
                      class="fullWidth"
                      @blur="validateName('job')"
                    />
                    <p class="formErrorMes" v-show="!jobTitle_valid">
                      Job title can not be empty
                    </p>
                  </div>
                </div>
              </div>
              <!-- country -->
              <div class="input-group position-r mb-20">
                <label>Country*</label>
                <select v-model="country">
                  <option value="" disabled>Select from the list</option>
                  <option
                    v-for="market in markets"
                    :key="market.MarketId"
                    :value="market.MarketName"
                  >
                    {{ market.MarketName }}
                  </option>
                </select>
                <p class="formErrorMes" v-show="!country_valid">
                  Please select the country from list.
                </p>
              </div>
              <div
                class="field-row mb-0 flex justify-between position-r gap-15"
              >
                <!-- password -->
                <div class="input-group position-r mb-20">
                  <label>Password*</label>
                  <div
                    @click="togglePassword"
                    v-show="pswd.length > 0 || pswd2.length > 0"
                    class="icon fixRight iconBtn cursor-pointer"
                  >
                    <font-awesome-icon
                      :icon="pswdVisible ? 'eye-slash' : 'eye'"
                    />
                  </div>
                  <!-- first password -->
                  <div class="iconInput">
                    <input
                      :type="pswdType"
                      v-model="pswd"
                      @input="passwordCheck"
                      required
                      class="fullWidth"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="input-group position-r mb-20">
                  <label>Repeat password*</label>
                  <!-- second password -->
                  <div class="iconInput">
                    <input
                      :type="pswdType"
                      v-model="pswd2"
                      required
                      class="fullWidth"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <p class="formErrorMes" v-show="pswd !== pswd2">
                  Password and Repeat password do not match
                </p>
              </div>
              <!-- password validation hint -->
              <div
                v-show="!pswdValidated && (pswd.length > 0 || pswd2.length > 0)"
                class="hintBox"
              >
                <h3>Password requirements:</h3>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': pswd.length >= 8 }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="pswd.length >= 8"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!(pswd.length >= 8)"
                  />
                  Longer than 8 characters
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_uppercase }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_uppercase"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_uppercase"
                  />
                  Has a capital letter
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_number }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_number"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_number"
                  />
                  Has a number
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_special }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_special"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_special"
                  />
                  Has a special character
                </p>
              </div>
              <!-- terms and condition -->
              <div class="input-group position-r mb-20">
                <div class="mb-20 position-r">
                  <label class="sm-text checkbox-label justify-start">
                    <a-checkbox v-model="termAgree"> I agree with </a-checkbox>

                    <a
                      href="#"
                      class="blueTextLink"
                      @click.prevent="termsPreviewModal = true"
                    >
                      Hotspex Labs Terms and conditions</a
                    >*
                  </label>
                  <p class="formErrorMes" v-show="!term_valid">
                    You need to agree with the Hotspex Labs Terms and
                    conditions.
                  </p>
                </div>
                <div class="gap"></div>
                <div>
                  <a-button
                    type="primary h-50 w-full"
                    :disabled="formValidated"
                    @click.prevent="register"
                  >
                    CREATE ACCOUNT
                  </a-button>
                </div>
              </div>
              <a-modal
                v-model="termsPreviewModal"
                width="90%"
                height="90vh"
                centered
                @cancel="termsPreviewModal = false"
                :footer="null"
                :header="false"
                wrapClassName="ant-modal--terms"
              >
                <iframe
                  src=" https://www.hotspexlabs.com/termsandconditions.html"
                  width="100%"
                  height="100%"
                  frameborder="0"
                >
                </iframe>
              </a-modal>
              <div class="formBody__signup">
                Already have an account?
                <router-link :to="{ name: 'login' }" class="blueTextLink"
                  >Login here</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- form feedback -->
        <div
          v-html="feedback"
          class="feedback"
          :class="{ expand: registerStatus !== -1 }"
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
export default {
  data() {
    return {
      pswdVisible: false,
      pswdValidated: false,
      formValidated: false,
      termAgree: false,
      email: "",
      fstName: "",
      lastName: "",
      cpName: "",
      jobTitle: "",
      country: "",
      pswd: "",
      pswd2: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      email_valid: true,
      fstName_valid: true,
      lastName_valid: true,
      cpName_valid: true,
      jobTitle_valid: true,
      term_valid: true,
      country_valid: true,
      feedback: "",
      registerStatus: -1,
      markets: [],
      termsPreviewModal: false,
    };
  },
  watch: {
    termAgree(newVal) {
      this.term_valid = newVal;
    },
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
  },
  methods: {
    passwordCheck() {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      this.has_number = /\d/.test(this.pswd);
      this.has_uppercase = /[A-Z]/.test(this.pswd);
      this.has_special = format.test(this.pswd);

      if (
        this.pswd.length >= 8 &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.pswdValidated = true;
      } else {
        this.pswdValidated = false;
      }
    },
    validateEmail() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email_valid = emailFormat.test(this.email);
    },
    validateName(field) {
      switch (field) {
        case "fstName":
          this.fstName_valid = this.fstName !== "";
          break;
        case "lastName":
          this.lastName_valid = this.lastName !== "";
          break;
        case "company":
          this.cpName_valid = this.cpName !== "";
          break;
        case "job":
          this.jobTitle_valid = this.jobTitle !== "";
          break;
        case "country":
          this.country_valid = this.country !== "";
          break;
        default:
          break;
      }
    },
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    register() {
      this.formValidated = true;

      this.validateEmail();
      if (!this.email_valid) {
        this.formValidated = false;
      }

      if (!this.termAgree) {
        this.term_valid = false;
        this.formValidated = false;
      }

      this.validateName("fstName");
      this.validateName("lastName");
      this.validateName("company");
      this.validateName("job");
      this.validateName("country");

      if (
        !this.fstName_valid ||
        !this.lastName_valid ||
        !this.cpName_valid ||
        !this.jobTitle_valid ||
        !this.country_valid
      ) {
        this.formValidated = false;
      }

      if (!this.pswdValidated) {
        this.formValidated = false;
      }

      if (!this.formValidated) return;

      const registerPromise = new Promise((resolve) => {
        wsUtils.RegisterUser(resolve, {
          email: this.email,
          firstName: this.fstName,
          lastName: this.lastName,
          company: this.cpName,
          jobTitle: this.jobTitle,
          country: this.country,
          password: this.pswd,
          planID: 1,
          // AccountID: 0,
          ClientIDs: "",
          UserTypeID: 0,
        });
      });
      registerPromise.then((data) => {
        this.showFeedback(data);
      });
    },
    register2() {
      this.showFeedback({ userID: 0, Status: 1 });
    },
    showFeedback(data) {
      if (data.userID !== 0) {
        this.feedback =
          "<h2>Thank You</h2><p>Your account needs to be verified before you can login and start testing.<p><p>Please check your inbox for further information.</p>";
        this.registerStatus = 1;
      } else {
        this.registerStatus = 0;
        if (data.Status === 1) {
          this.feedback =
            "<h2>Oops!</h2><p>It looks like that account may already exist. Please check your inbox for instructions on how to complete the registration process.</p><p>If you can't find the email in your Inbox, please check your Junk/SPAM folder or contact <a href='mailto:support@hotspexstudio.com' >support@hotspexstudio.com</a> for assistance.<p>";
        } else if (data.Status === 3) {
          this.feedback =
            "<h2>Oops!</h2><p>This domain belongs to an agency/enterprise account please contact your admin.<p>";
        } else {
          this.feedback =
            "<p>Oops!It seems we have some problems here. Please contact to </p><p>Please contact <a href='mailto:support@hotspexstudio.com' >support@hotspexstudio.com</a> for assistance.</p>";
        }
      }
    },
  },
  created() {
    const marketPromise = new Promise((resolve) => {
      wsUtils.MarketList(resolve);
    });
    marketPromise.then((data) => {
      this.markets = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.signup {
  background: var(
    --button-gradient,
    linear-gradient(99deg, #fb3b51 -11.27%, #a14be7 109.61%)
  );
  height: 100%;
  background-attachment: fixed;
  overflow-x: hidden;
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  text-align: center;
  max-height: 0;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
  padding: 20px;
}

.form {
  margin: 2em 0;
}

.h-centerContent {
  margin: 2em 0;
}

.hintBox {
  background: #f6f6f6;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin: 30px 0 20px;
  h3 {
    font-size: 13px;
  }
}

.frmValidation {
  font-size: 13px;
  margin-bottom: 5px;
  padding-left: 18px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: var(--hsred);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.frmValidation--passed .frmIcon {
  color: var(--succeedGreen);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

::v-deep .ant-modal {
  &--terms {
    .ant-modal-body {
      height: 90vh;
    }
  }
}
</style>
